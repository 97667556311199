import { computed, defineComponent, inject, useContext, } from '@nuxtjs/composition-api';
import { SfRadio, SfHeading } from '@storefront-ui/vue';
export default defineComponent({
    components: {
        SfRadio,
        SfHeading,
    },
    props: {
        filter: {
            type: Object,
            required: true,
        },
    },
    setup() {
        const { app: { i18n } } = useContext();
        const { isFilterSelected } = inject('UseFiltersProvider');
        const selected = computed(() => ((id, optVal) => isFilterSelected(id, optVal)));
        const label = (option) => `${(option.value === '1' ? i18n.t('Yes') : i18n.t('No'))}`;
        return { selected, label };
    },
});
